import React, { Component } from 'react';
import './Navigation.css';

class Navigation extends Component {
  render() {
    return (
      <nav id="navigation">
        <ul>
          <li></li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
