import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
      </footer>
    );
  }
}

export default Footer;
