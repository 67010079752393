import React, { Component } from 'react';
import Navigation from '../navigation/Navigation';
import './Header.css';

class Header extends Component {
  render() {
    return (
      <header className="header">
        <Navigation />
        <h1 className="main-title">{this.props.name}</h1>
      </header>
    );
  }
}

export default Header;
